/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.shapedividers_com-2802 {
  overflow: hidden;
  position: relative;
  width: 100px;
}
.shapedividers_com-2802::before {
  content: "";
  font-family: "shape divider from ShapeDividers.com";
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 101;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 100px 100%;
  background-position: 100% 50%;
}

@media (min-width: 768px) {
  .shapedividers_com-2802::before {
    background-size: 100px 100%;
    background-position: 100% 50%;
  }
}

@media (min-width: 1025px) {
  .shapedividers_com-2802::before {
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw;
    background-size: 100px 100%;
    background-position: 100% 50%;
  }
}
